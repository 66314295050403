<template>
  <div>
    <base-material-card
      :color="pagestyles.commitmentFrameColor"
      class="px-4 py-2"
    >
      <template v-slot:heading>
        <div
          class="display-2 font-weight-bold"
        >
          {{ $t('titleComponentAvailableCommitments') }}
        </div>
      </template>
      <v-card-text>
        <v-data-table
          :headers="commitmentHeaders"
          :items="commitmentProducts"
          item-key="lineNumber"
          hide-default-footer
          single-select
          class="elevation-1"
        >
          <template
            v-slot:top
          >
            <div
              class="pb-2"
            >
              {{ $t('subTitleComponentAvailableDetails') }} {{ partnerName }}
            </div>
          </template>
          <template v-slot:item.availableQuantity="{ item }">
            <span
              class="font-weight-bold"
            >
              {{ item.availableQuantity }} {{ item.unitText }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import i18n from '@/i18n'
  import { mapFields } from 'vuex-map-fields'
  import dashboardAPI from '@/services/dashboard'
  import customColors from '@/styles/appcolors'
  function initialState () {
    return {
      showErrorDialog: false,
      errorMessage: '',
      modelProcessing: false,
      partnerName: '',
      pagestyles: {
        commitmentFrameColor: customColors.Transaction.commitmentFrameColor,
        commitmentTableColor: customColors.Transaction.commitmentTableColor,
      },
      commitmentHeaders: [
        {
          text: i18n.t('col-commitment-ProductGroup'),
          align: 'start',
          value: 'productName',
          sortable: false,
          class: 'info display-1 font-weight-bold',
        },
        {
          text: i18n.t('col-commitment-Quantity'),
          align: 'end',
          value: 'availableQuantity',
          sortable: false,
          class: 'info display-1 font-weight-bold',
          width: '25%',
        },
      ],
      commitmentProducts: [],
    }
  }
  export default {
    name: 'CommitmentComponent',
    components: {
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    props: {
      dataType: {
        type: Number,
        default: 1,
      },
      groupId: {
        type: Number,
        default: 0,
      },
      partnerId: {
        type: Number,
        default: 0,
      },
      quantity: {
        type: Number,
        default: 0,
      },
    },
    data: function () {
      return initialState()
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        partners: 'partners',
      }),
    },
    mounted: function () {
      this.commitmentProducts = []
      if (this.partnerId !== 0) {
        this.modelProcessing = true
        dashboardAPI.getDashboardDetailsData(this.partnerId)
          .then(response => {
            for (const item of response.data.details) {
              if (this.groupId !== 0) {
                if (item.groupId === this.groupId) {
                  this.commitmentProducts.push(item)
                }
              } else {
                this.commitmentProducts.push(item)
              }
            }
            if (this.partners.length === 0) {
              this.$store.dispatch('GET_PARTNERS')
                .then(response => {
                  this.updatePartnerName()
                })
            } else {
              this.updatePartnerName()
            }
            this.modelProcessing = false
          })
          .catch(error => {
            this.showErrorDialog = true
            this.errorMessage = error.message
            this.modelProcessing = false
          })
      }
    },
    methods: {
      updatePartnerName: function () {
        this.partnerName = ''
        for (const item of this.partners) {
          if (this.partnerId === item.partnerId) {
            this.partnerName = item.name
            break
          }
        }
      },
    },
  }
</script>
